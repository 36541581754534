import { useEffect } from "react";
import { HeaderImage } from "@components/HeaderImage";
import { useKeycloak } from "@react-keycloak-fork/ssr";
import type { KeycloakInstance } from "keycloak-js";
import { useRouter } from "next/router";
import { basePath, sucursalVirtualLink } from "@lib/utils/constants";

function Home() {
  const { keycloak } = useKeycloak<KeycloakInstance>();
  const router = useRouter();

  const Signin = () => {
    if (keycloak) {
      window.location.href = keycloak.createLoginUrl();
    }
  };

  useEffect(() => {
    if (keycloak?.authenticated) {
      router.push(sucursalVirtualLink);
    }
  }, [keycloak?.authenticated]);

  return (
    <div align="center" justify="center" flex={1}>
      <HeaderImage />
      <div className="flex flex-col items-center">
        <div className="box-border bg-principal-150 border-transparent w-30 sm:w-2/4 h-60  p-4 shadow-xl mt-8 mb-8 mr-7 ml-7 sm:mr-30 sm:ml-30 rounded-lg">
          <div className="flex justify-center mt-5">
            <span className="text-principal-100 text-3xl font-poppins font-bold ">
              ¡Bienvenidos!
            </span>
          </div>
          <div className="flex justify-center mt-3 mr-6 ml-6">
            <span className="text-principal-450 text-base font-poppins font-bold">
              Regístrate o accede a tu cuenta para iniciar sesión y gestionar
              tus solicitudes
            </span>
          </div>
          <div className="flex justify-center mt-6">
            <div className="mt-8 justify-center w-2/3 h-10 text-principal-150 bg-principal-750 rounded-2xl border-2 mb-28">
              <button className="font-semibold p-2" onClick={Signin}>
                Iniciar solicitud de subsidio
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
